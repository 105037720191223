.img {
    width: 100%;
    margin-bottom: 20px;
}

.grayBox {
    background-color: #F1F1F1;
    /* margin-top: 10px !important; */
}

.guaranteedTable {
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
    border: transparent !important;
}

.grayBoxContainer {
    padding: 40px 0;
}

.guaranteedTableCell {
    background-color: #D9A22E !important;
    color: white !important;
    padding: 10px 16px !important;
}

.firstCell {
    border-radius: 10px 0 0 10px !important;
}

.lastCell {
    border-radius: 0px 10px 10px 0px !important;
}

.blankCell {
    background-color: #FFF !important;
    border-bottom: 0px !important;
    padding: 5px !important;
}

.maxLabel {
    color: #078A4E !important;
    background-color: #b7d7c8 !important;
    margin-left: 5px !important;
}

.maxLabel:hover {
    color: #078A4E !important;
    background-color: #b7d7c8 !important;
}

.recharts-text {
    font-size: 14px !important;
    font-family: "roboto", 'Open Sans', sans-serif !important
}

.minLabel {
    color: #FFA500 !important;
    background-color: #F4DDC1 !important;
    margin-left: 5px !important;
}

.minLabel:hover {
    color: #FFA500 !important;
    background-color: #F4DDC1 !important;
}

.recharts-legend-item,
.recharts-tooltip-item-list li {
    float: unset;
    padding: unset !important;
    border: none;
}

.guaranteedTableBodyRow {
    background-color: #F1F1F1 !important;
}

.guaranteedTableBodyRow td {
    padding: 10px 16px !important;
}

.img_text1 {
    transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
    color: white;
    position: absolute;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color {
    background-color: #F1F1F1 !important;
}

.third_box {
    display: flex;
    justify-content: flex-end;
    width: 97%;
}

.third_box button {
    min-width: 136px !important;
}

.img_text2 {
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    position: absolute;
    color: white;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_div {
    position: relative;
    text-align: center;
}

.main_container {
    height: max-content;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;

}

.first_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    color: white;
    font-size: larger;

    background-color: #D9A22E;
}

.home_page_footer {
    margin-top: 40px;
}

.first_part {
    display: flex;
    justify-content: space-around;
}

.first_box {
    /* padding: 32px;
    width: 60%; */
    /* height: 330px; */
    padding: 10px 3px 32px 3px;
    width: 50%;
}

.second_box {
    /* margin: 20px 45px 45px 45px;
    border: 2px solid #F1F1F1;
    background-color: #F1F1F1 ;
    width: 40%;
    height: 230px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center; */

    margin: 20px 5px 45px 5px;
    border: 2px solid #F1F1F1;
    background-color: #F1F1F1;
    width: 90%;
    height: 230px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.input1,
.input2,
.input3 {
    padding: 5px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.input4 {
    padding-left: 17px;
}

.input1 .MuiFormControl-root,
.input2 .MuiFormControl-root,
.input3 .MuiFormControl-root {
    width: 85%;
}

.input2 .MuiFormControl-root .MuiAutocomplete-root .MuiFormControl-fullWidth {
    width: 100% !important;
    color: black;
}

.img_text1,
.img_text2 {
    margin-bottom: 20px;
    display: inline-block;
    color: white !important;
}

.btn_for_Calaculator {
    padding: 4px;
    margin: 5px;
    width: 80px;
    height: 30px;
    border-radius: 7px;
    background-color: #F1F1F1;
    border: 0px;

}

.period_text {
    /* margin-left: 30px; */
    /* margin-top: 20px; */
    font-size: small;
}

.btn_div {
    /* margin-left: 20px; */
    margin-top: 10px;
}

.grid_item1 {
    background-color: rgb(217, 162, 46);
    margin-right: 4% !important;
    color: #FFF;
}

.item {
    border-radius: 10px;
}

.grid_item2 {
    background-color: rgb(217, 162, 46);
    justify-content: center;
    align-items: center;
    margin-right: 10px !important;
}

.itemtextcenter {
    text-align: center;
}

.grid_item3 {
    background-color: rgb(217, 162, 46);
    justify-content: center;
    align-items: center;
}

.grid_item {
    background-color: rgb(241, 241, 241);
    justify-content: center;
    align-items: center;
    margin-right: 10px !important;
}

button {
    cursor: pointer;
}

.chart {
    margin-top: 20px;
}

.main_container .MuiDivider-fullWidth {
    width: 90% !important;
    border-color: rgb(217, 162, 46) !important;
    margin: 0 auto !important;
}

.chart canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70% !important;
    height: 10% !important;
    margin: auto;
}

.con_main {
    margin: 20px 0 !important;
    padding-bottom: 20px;
    /* padding-right: 25px; */
    /* max-height: 1400px;
    min-height: fit-content;
    height: 500px; */
    justify-content: space-evenly;
}

.box_height {
    text-align: center !important;
    padding: 7px !important;
    margin: auto;
}

h2 {
    margin: 0px !important;
}

.first {
    display: flex;
}

.first_box1 {
    /* width: 60%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column; */
    width: 52%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.first_box3 {
    width: 52%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input11 .MuiTextField-root {
    width: 85% !important;
    padding-left: 12px !important;
}

.period_text,
.Client_age,
.Client_name {
    /* margin-left: 5px !important; */
    color: grey;
}

.first_box2 {
    /* width: 40%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column; */
    width: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.age_state {
    display: flex;
}

.first_box1 .input1 .MuiFormControl-root {
    padding-left: 12px;
}

.first_box2 .input1 .MuiFormControl-root {
    /* padding-right: 23px;   */
    padding-right: 11px;
}

.Client_name {
    /* margin-left: 40px;
    font-size: small; */
    margin-left: 35px;
    font-size: small;
}

.Prepared_By {
    color: grey;
    font-size: small;
    margin-left: 25px;
}

.Client_age {
    /* margin-left: 15px; */
    /* width: 85%;
    margin: 0 auto;
    font-size: small; */
    margin-left: 40px;
    font-size: small;
}

.first_box4 {
    width: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input11 {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    display: flex;
    justify-content: center;
}

/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    border-color: none !important;
} */
.savebtn {
    display: flex !important;
    background-color: rgb(217, 162, 46) !important;
    justify-content: center !important;
    margin: 0 auto !important;
    margin-top: 20px !important;

}

.secBox :nth-child(2) {
    justify-content: center;
    display: flex;
}

.btn_div1 {
    display: flex;
    /* justify-content: end; */
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.btn_div1 button {
    background-color: #D9A22E;
    margin-right: 20px;
}

.download_btn {
    width: 10% !important;
    margin-top: 15px !important;
    background-color: #D9A22E !important;
}

.btn_btn {
    padding: 0 !important;
    border: none !important;
    margin-top: 45px !important;
}

@media screen and (min-width:1000px) and (max-width:1670px) {
    .main_container {
        width: 90%;
    }

    .btn_div1 {
        width: 90%;
    }
}

@media screen and (min-width:0px) and (max-width:614px) {
    .first {
        display: flex;
        flex-direction: column;
    }

    .first_box1,
    .first_box2 {
        width: 100%;
    }

    .btn_div1 {

        width: 90%;

    }

    .Client_age,
    .Client_name {
        margin-left: 5px !important;
        color: grey;
    }

    .first_box1 .input1 .MuiFormControl-root {
        padding-left: 0px;
    }

    .first_box2 .input1 .MuiFormControl-root {
        padding-right: 0px;
    }

    .main_container {
        width: 90%;
        margin-top: 30px !important;
    }

    .first_part {
        display: flex;
        flex-direction: column;
    }

    .first_box {
        width: 100%;
        padding: 15px;
    }

    .second_box {
        width: 90%;
        margin: 0 auto;
        align-items: center;
        margin-bottom: 20px;
    }

    .chart {
        height: 200px !important;
    }

    .chart canvas {
        height: 210px !important;
        width: 90% !important;
    }

    /* .css-n2ywgu-MuiGrid-root, .css-1thszbi-MuiGrid-root{
        text-align: left !important;
        } */
    .img_div {
        display: none;
    }

    .Prepared_By {
        margin-left: 0px
    }

    .Client_name {
        /* margin-left: 5px; */
        display: flex;
        width: 85%;
        /* margin-left: 8% !important; */
    }

    .input11 .MuiTextField-root {
        width: 100% !important;
        padding-left: 0px !important;
    }

    .form_control {
        margin: 0px !important;
    }

    .input4 {
        padding-left: 0px;
    }

    .period_text {
        margin-left: 5px;
        margin-top: 6px;
        font-size: small;
    }

    .btn_div {
        margin-left: 0px;
    }

    .con_main_box {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .first_box1 .input1 .MuiFormControl-root .MuiInputBase-root input {
        padding-left: 3px !important;
    }

    .con_main {
        width: 1000px !important;
    }

    .grid_item1 {
        /* background-color: rgb(217, 162, 46); */
        margin-right: 4% !important;
    }

    /* .con_main .MuiGrid-root .MuiGrid-root {
        margin: 5px !important;
    } */
    .rightfirstcomponent {
        width: 90% !important;
        padding: 10px 0px 0px 0px;
        margin: 0 auto;
    }

    .rightbox {
        width: 100% !important;
        margin: 0 auto;
    }

    .age_state {
        display: block;
    }

    .first_box3,
    .first_box4 {
        width: 100%;
    }

}

.btn_div1 button:hover {
    background-color: #D9A22E;
}

.rightbox {
    width: 40%;
}

.rider_header {
    color: grey;
    font-size: smaller;
}

.rightfirstcomponent {
    width: 100%;
    padding: 10px 0px 0px 0px;
}

.check_box {
    display: flex;
    width: 107%;
}

.fm1,
.fm2 {
    width: 57%;
}

@media screen and (min-width:421px) and (max-width:614px) {

    .input1 .MuiFormControl-root,
    .input2 .MuiFormControl-root,
    .input3 .MuiFormControl-root {
        width: 100% !important;
    }

    .btn_div1 {

        width: 90%;

    }
}

@media screen and (min-width:615px) and (max-width:999px) {
    .con_main_box {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .con_main {
        width: 1000px !important;
    }

    .btn_div1 {

        width: 90%;

    }

    .main_container {
        width: 90%;
    }
}

.secBox {
    text-align: center;
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {

    .main_container_history,
    .btn_div1 {
        width: 90% !important;
    }

    .btn_div1 {

        width: 90%;

    }
}

.errCalculator {
    width: 85%;
    margin: 7px auto;
    display: flex;
    justify-content: start !important;
}

/* .css-wgai2y-MuiFormLabel-asterisk{
    display: none;
} */
.itemtextcenter div {
    min-height: 25px;
}