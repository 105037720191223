* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: 'Arimo', sans-serif; */
}

.global_header {
    width: 100%;
    height: 100px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company_logo img {
    height: 90px;
    padding-left: 25px;
    margin-top: 10px;
}

.my_bussiness_btn {
    padding: 0px !important;
    background-color: #D9A22E !important;
    border: none !important;
}

.my_bussiness_link {
    padding: 5px 15px !important;
    color: #fff !important;
}

.selected_page {
    color: #074a6e !important;
}

.header_right_section {
    padding-right: 10px;
}

.header_right_section a {
    padding: 10px 15px;
    text-transform: capitalize;
    text-decoration: none;
    color: #000000;
    font-size: 15px;
}

#user_options_menu .MuiPaper-root {
    top: 7% !important;
    left: 89% !important;
}

#history_options_menu .MuiPaper-root {
    top: 7% !important;
    left: 74% !important;
}

#history_options_menu .MuiPaper-elevation8 {
    min-width: 230px !important;
}

#history_options_menu_agent .MuiPaper-root {
    top: 7% !important;
    left: 83% !important;
}

#history_options_menu_agent .MuiPaper-elevation8 {
    min-width: 230px !important;
}

.menu_item_list a {
    text-decoration: none;
    color: #000000;
}

#user_options_menu .MuiPaper-root ul li a {
    text-decoration: none;
}

@media screen and (min-width:0px) and (max-width: 400px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 42% !important;
    }
}

@media screen and (min-width:400px) and (max-width: 500px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 47% !important;
    }
}

@media screen and (min-width:500px) and (max-width: 630px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 55% !important;
    }
}

@media screen and (min-width:631px) and (max-width: 700px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 71% !important;
    }
}

@media screen and (min-width:700px) and (max-width: 800px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 75% !important;
    }
}

@media screen and (min-width:800px) and (max-width: 900px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 78% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 6% !important;
        left: 50% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }
}

@media screen and (min-width:900px) and (max-width: 1000px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 81% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 6% !important;
        left: 45% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 65% !important;
    }
}

@media screen and (min-width:1000px) and (max-width: 1100px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 80% !important;
        position: absolute;
        right: 2%;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 50% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 68% !important;
    }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 84% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 55% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }
}


@media screen and (min-width:1200px) and (max-width: 1300px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 85% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 60% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 75% !important;
    }
}

@media screen and (min-width:1300px) and (max-width: 1450px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 87% !important;
    }
}

@media screen and (min-width:1300px) and (max-width: 1499px) {
    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 65% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 78% !important;
    }
}

@media screen and (min-width:1500px) and (max-width: 1770px) {
    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 80% !important;
    }
}


@media screen and (max-width: 550px) {
    .header_right_section a {
        padding: 10px 5px !important;
        font-size: 13px;
    }

    .company_logo img {
        padding-left: 2px;
        width: 300px;
    }

    /* #user_options_menu .MuiPaper-root{
        left: 39% !important;
    } */
}

@media screen and (max-width: 420px) {
    .company_logo img {
        width: 230px;
    }
}

@media screen and (max-width: 350px) {
    .global_header {
        flex-direction: column;
        height: 130px;
    }

    #user_options_menu .MuiPaper-root {
        top: 18% !important;
        left: 32% !important;
    }

    .company_logo img {
        width: 230px;
    }
}


/* scrollbar */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D9A22E;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #D9A22E;
}