.main_container_announcement {
    width: 65%;
    margin: 30px auto !important;
    box-shadow: 0px 0px 4px #D9A22E;
}

.AnnResourceTableHeaderRow {
    background-color: #D9A22E !important;
}

.AnnResourceTableHeaderRow th {
    background-color: #D9A22E !important;
    color: #FFFFFF !important;
    padding: 12px 16px 12px 16px !important;
}

.AnnResourceTableHeaderRow th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.AnnResourceTableHeaderRow th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.AnnResourceTableRow td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.AnnResourceTableRow td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.AnnResourceTableRow {
    background-color: #f1f1f1 !important;
}

.AnnResourceTableRow td {
    border-bottom: none !important;
}

.AnnResourceTableRow td {
    padding: 8px 16px 8px 16px !important;
}