.first_box_view {
    margin: 20px auto;
    height: 210px;
    width: 50%;
}

.second_box_view {
    height: 130px !important;
    margin: 20px 5px 15px 5px !important;
}

.first_view {
    width: 21%;
}

.second {
    width: 2%;
}

.name,
.age,
.period,
.state,
.rider,
.amount {
    padding: 5px;
}

.btn_div1_view button {
    background: #D9A22E;
}

.btn_div1_view button:hover {
    background: #D9A22E;
}

.btn_div1_view {
    display: flex;
    width: 90%;
    margin: 14px auto;

}

.note {
    color: red;
    text-align: justify;
    width: 90%;
    margin-bottom: 10px;

}

.state_view {
    position: unset;
}

.main_container_view .MuiDivider-fullWidth {
    margin: 10px auto !important;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .first_box_view {
        margin: 10px auto;
        height: auto;
        width: 90%;
    }

    .first_view {
        width: 37%;
    }

    .third_rider {
        max-width: 50%;
    }

    .btn_div1_view {
        display: flex;

        width: 90%;
        margin: 14px auto;

    }

    .btn_div1_view button {
        background: #D9A22E;
    }

    .note {
        margin: 0 auto;
        margin-bottom: 10px;
    }

}


@media screen and (min-width:421px) and (max-width:615px) {
    .first_box_view {
        margin: 10px auto;
        height: auto;
        width: 90%;
    }

    .first_view {
        width: 30% !important;
    }

    .third {
        width: 70% !important;
    }

    .note {
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .second_box_view {
        height: none !important;
        margin: 0 !important;
    }

}

.first_view {
    width: 30% !important;
}

.third {
    width: 70% !important;
}

@media screen and (min-width:616px) and (max-width:820px) {
    .first_box_view {
        width: 37%;
        margin: 20px auto;
        height: auto;
    }

    .rightbox {
        width: 55%;
    }

    .first_part {
        display: flex;
        justify-content: space-evenly;
    }

    .second_box_view {
        margin: 20px 5px 15px 5px
    }

}

@media screen and (min-width:821px) {
    .first_box_view {
        height: 250px !important;
    }

}

@media screen and (min-width:1671px) {
    .btn_div1_view {
        width: 60%;
    }

}