.beneficiaries_section_row_4 .MuiFormControl-root{
    width: 130px;
}

/* .beneficiaries_section_row_3 .MuiFormControl-root .MuiInput-underline{
    width: 230px;
} */

@media screen and (min-width:820px) and (max-width:920px) {
    .beneficiaries_section_row_3 .MuiFormControl-root .MuiInput-underline{
        width: 170px;
    }
}
@media screen and (min-width:421px) and (max-width:820px) {
    .beneficiaries_section_row_3 .MuiFormControl-root .MuiInput-underline{
        width: 100px;
    }
}
@media screen and (min-width:0px) and (max-width:420px) {
    .important_notice_question_c_row {
        flex-direction: column;
        display: flex;
    }
    .beneficiaries_section_row_1 ,.beneficiaries_section_row_2,.beneficiaries_section_row_3 ,.beneficiaries_section_row_4  {
        width: 100%;
    }
    .MuiFormControl-root
    .beneficiaries_section_row_1  .MuiFormControl-root,
    .beneficiaries_section_row_2 .MuiFormControl-root,
    .beneficiaries_section_row_2 .MuiFormControl-root,.beneficiaries_section_row_3 .MuiFormControl-root
     ,.beneficiaries_section_row_4 .MuiFormControl-root{
        width: 100%;
    }
    .first_question {
        margin: 30px !important;
    }
    .application_form .application_form_card .application_form_head {
        margin-top: 30px;
    }
    .beneficiaries_section_row_4{
        padding-right: 10px;
    }  
}
@media screen and (min-width:421px) and (max-width:820px) {
    .beneficiaries_section_row_1  .MuiFormControl-root,  .beneficiaries_section_row_3 .MuiFormControl-root .MuiInput-underline {
        width: 100%;
    }
    
}
@media screen and (min-width:421px) and (max-width:721px) {
   
    .application_form .application_form_card .application_form_head {
        margin-top: 20px;
    }
    .important_notice_question_c_row {
        flex-direction: column;
        display: flex;
    }
    .beneficiaries_section_row_1 ,.beneficiaries_section_row_2,.beneficiaries_section_row_3 ,.beneficiaries_section_row_4  {
        width: 100%;
    }
    .beneficiaries_section_row_1  .MuiFormControl-root,
    .beneficiaries_section_row_2 .MuiFormControl-root,
    .beneficiaries_section_row_2 .MuiFormControl-root,.beneficiaries_section_row_3 .MuiFormControl-root
     ,.beneficiaries_section_row_4 .MuiFormControl-root{
        width: 100%;
    }
    .beneficiaries_section_row_4{
        padding-right: 10px;
    } 
    .first_question{
        margin: 15px 35px 20px 35px !important;
    }
}
@media screen and (min-width:1000px) and (max-width:1300px) {
    .first_question{
        margin:20px 100px !important;
    }
    
}
@media screen and (min-width:721px) and (max-width:999px) {
    .first_question{
        margin:20px 80px !important;
    }
}
@media screen and (min-width:1300px) {
    .first_question{
        margin: 20px 100px;
    }
}