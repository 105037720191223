* {
  box-sizing: border-box;
}

.page_title {
  background: #D9A22E !important
}

.agentsSearchBarBox {
  width: 52%;
  text-align: center;
  margin-top: 20px;
}

.agentsSearchBarTextField input {
  padding: 10px 18px !important;
}

.agentsSearchBarTextField {
  background-color: rgb(241, 241, 241);
}

.agentsSearchBarTextField fieldset {
  border: none;
}

.agents_table_root {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10px auto;
}

.agents_table {
  /* margin-top: 20px; */
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.agents_table {
  white-space: nowrap;
}

.agents_table thead {
  margin-bottom: 10px;
}

.agents_table thead tr {
  border-radius: 50px !important;
}

.agents_table_head th {
  background-color: #D9A22E !important;
  color: #fff;
  font-size: 16px;
  padding: 8px;
}

.agents_table_head th:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.agents_table_head th:last-of-type {
  border-radius: 0 8px 8px 0;
}

.agents_status_button button {
  color: #000;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 2px;
}

.agents_table_data {
  background-color: #f1f1f1;
  margin-top: 10px;
  border-radius: 50px;
}

.agents_status_selection {
  width: 100px;
}

.agents_status_selection::after {
  border-bottom: none !important;
}

.agents_status_selection select {
  padding-right: 0 !important;
}

.agents_status_selection:before {
  border-bottom: 0 !important;
}

.agents_status label {
  display: none;
}

.agents_status_select {
  margin-top: 0 !important;
}

.agents_status_select:before {
  border: none !important;
}

.agent_status_option {
  padding: 10px !important;
}

.agent_status label {
  margin-top: -10px;
}

/* .agent_status .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
display: none;
} */
.agents_status_select div:first-of-type {
  min-width: 40px !important;
}

.agents_status_select:after {
  border: none !important;
}

.agents_table_data td {
  padding: 5px 10px;
  font-size: 16px;
}

.agents_table_data td:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.agents_table_data td:last-of-type {
  border-radius: 0 8px 8px 0;
}

.status_selection fieldset {
  border: none;
}

.status_selection div:first-of-type {
  padding: 0;
}


.pr-header-sub-item{
  max-width: 1200px !important;
  border: 1px solid #D9A22E;
  margin: 0 auto !important;
}

.pr-form-fields-item{
  margin: 5px 0px !important;
}

.pr-header-item{
  margin: 0 auto !important;
  background-color: #D9A22E;
  box-shadow: 0 0 4px #d9a22e;
  color: white;
  padding: 15px;
  text-align: center;
}

.pr-header-item span{
  font-size: 25px !important;
 font-weight: 500 !important;
  
}

.pr-back-btn{
  background-color: #D9A22E !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: 500 !important;

}

.pr-back-btn-item{
  margin: 0 auto 20px !important;
}


@media only screen and (max-width: 1150px) {
  .agents_table_root {
    width: 70%;
  }
}

@media only screen and (max-width: 950px) {
  .agents_table_root {
    width: 80%;
  }
}

@media only screen and (max-width: 580px) {
  .agents_table_root {
    width: 90%;
  }

  .agents_table {
    min-width: 470px;
    overflow-x: auto;
  }
}