.important_notice_detail {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.notice_detail_desc {
    color: #606060;
    margin-bottom: 15px !important;
}

.question_A_important_notice {
    color: #000;
    font-weight: 500;
}

.question_B_important_notice {
    color: #000;
    font-weight: 500;
}

.question_C_important_notice {
    color: #000;
    font-weight: 500;
}

.important_notice_question_c_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.question_D_important_notice {
    color: #000;
    font-weight: 500;
    margin-top: 15px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.question_d_input_field {
    margin-top: -22px !important;
    margin-left: 10px !important;
}

.broker_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

@media (max-width:1580px) and (min-width:1301px) {
    .question_d_input_field {
        margin-top: 0px !important;
    }
}

@media (max-width:831px) {
    .question_d_input_field {
        margin-top: -2px !important;
    }
}