.edit_pdf_popup2_group {
    width: 90% !important;
}

.autocomplete_group {
    width: 70%;
    margin: 0 auto;
}

.autocomplete_group div div div button {
    margin-top: 0px !important;
    background-color: rgb(241, 241, 241) !important;
}

.btn_group {
    margin: 10px 30px;
}

.hint {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.hint img {
    width: 60px;
    margin: 0 auto;
}

.hint_span {
    width: 70%;
    margin: 0 auto;
    text-align: justify;
    color: red;
}

.span_all_data {
    width: 55%;
    margin: 0 auto;
    text-align: justify;
    color: red;
}

.Grid_main_group {
    margin: 20px auto !important;
    padding-bottom: 20px;
    width: 95% !important;
    /* padding-right: 25px; */

    justify-content: space-evenly;
}

.btn1_group {
    margin-right: 10px !important;
}

.btn2_group,
.btn1_group {
    margin: 10px auto;
    /* width: 45%; */

    max-height: 40px;
    background-color: #D9A22E !important;
}

.edit_pdf_popup2_group {
    min-width: 400px;
}

@media screen and (min-width: 421px) and (max-width: 618px) {

    .btn2_group,
    .btn1_group {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;
        background-color: #D9A22E !important;
    }

    .MuiGrid-spacing-xs-2 .MuiDialogActions-root {
        overflow: hidden;
    }

    .edit_pdf_popup2_group {
        min-width: 0px !important;
    }

    .MuiGrid-spacing-xs-2 .MuiDialogActions-root {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width:0px) and (max-width:420px) {

    .Grid_main_group {
        min-width: 700px;
        max-width: 1000px;
    }

    .Grid_box_group {
        width: 95%;
        overflow-x: scroll;
        margin: 0px 0px 0px 10px;
    }

    .btn2_group,
    .btn1_group {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;
        background-color: #D9A22E !important;
    }

    .MuiGrid-spacing-xs-2 .MuiDialogActions-root {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width:421px) and (max-width:820px) {

    .Grid_main_group {
        min-width: 700px;
        max-width: 1000px;
    }

    .Grid_box_group {
        width: 95%;
        overflow-x: scroll;
        margin: 0px 0px 0px 10px;
    }
}