.Grid_box_Pdf_config {
    height: 71vh;
    overflow: auto;
}

.page_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;

    color: #FFFFFF;
    font-weight: 500;
    font-size: 23px;
}

.main_container_pdf_history {
    height: auto;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;
}

.btns {
    margin: 20px 12px;
}

.Grid_main_Pdf_config p {
    margin: 0;
}

.btn_New_Pdf,
.btn1,
.btn2,
.btn3,
.btn4 {
    background-color: #D9A22E !important;
}

.btn_New_Pdf,
.btn1 {
    /* width:120px !important;
    padding: 18px !important; */
    margin-right: 10px !important;
}

/* .btn3{
    margin-left: 10px !important;
} */


.resourceTableHeaderRow {
    background-color: #D9A22E !important;
}

.resourceTableHeaderRow th {
    background-color: #D9A22E !important;
    color: #FFFFFF !important;
    padding: 12px 16px 12px 16px !important;
}

/* .resourceTableHeaderRow th {
    border-radius: 10px solid red;
} */

.resourceTableHeaderRow th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.resourceTableHeaderRow th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.resourceTableRow td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.resourceTableRow td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.resourceTableRow {
    background-color: #f1f1f1 !important;
}

.resourceTableRow td {
    border-bottom: none !important;
}

.resourceTableRow td {
    padding: 10px 5px !important;
}

.resourceTable {
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}


.file-inputs input {
    height: 150px !important;
    width: 300px !important;
}

.file-inputs {
    background-color: aliceblue !important;
    margin: 0 !important;
}

.file-inputs span {

    color: #D9A22E !important;
}

.box_height {
    text-align: left !important;
}

.autocomplete div div div button {
    BACKGROUND: aliceblue !important;
    margin-top: -3px !important;
    color: black !important;
}

.btn-search-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: 3%; */
}

.searchbar {
    /* background-color: aliceblue; */
    margin: 20px 17px 10px 20px;
    /* height: 38px; */
}

.searchResource input {
    padding: 9px 14px !important;
    background-color: #f1f1f1 !important;
}

.searchResource fieldset {
    border: none;
}


.edit_pdf_popup1 .MuiPaper-root {
    width: 600px;
    /* height: 400px !important; */
}

.edit_pdf_popup2 {
    width: 90% !important;
}

#filled-hidden-label-small {
    padding-top: 12px !important;
}

.MuiInputAdornment-root .MuiSvgIcon-root {
    margin-top: -10px !important;
}

.btn2,
.btn3 {
    margin-right: 10px !important;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .btns {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .main_container_pdf_history {
        width: 95% !important;
    }

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .btn_New_Pdf,
    .btn1 {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;

    }

    .searchbar .MuiFormControl-root {
        width: 100%;
    }

    .btn2,
    .btn3,
    .btn4 {
        width: 50%;
        max-height: 40px;
        margin: 10px auto;
    }

    .searchbar {
        margin: 5% 3%;
    }

}

@media screen and (min-width:421px) and (max-width:618px) {
    .btns {
        display: flex;
        justify-content: center;
        /* flex-direction: column; */
        margin: 0 auto;
    }

    .main_container_pdf_history {
        width: 95% !important;
    }

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .btn_New_Pdf,
    .btn1 {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;

    }

    .searchbar .MuiFormControl-root {
        width: 100%;
    }

    .btn2,
    .btn3 {
        width: 50%;
        max-height: 40px;
        margin: 10px auto;
    }

    .searchbar {
        margin: 5% 3%;
    }

}

@media screen and (min-width:618px) and (max-width:820px) {
    .btn3 {
        margin-top: 10px !important;
    }

    ;

    .btns {
        display: flex;
        justify-content: center;

    }

    ;

    .searchbar {
        width: fit-content !important;
        margin: 20px auto 0px !important;
    }

    ;

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    ;
}

@media screen and (min-width:821px) and (max-width:867px) {
    .btn3 {
        margin-top: 10px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1301px) {
    .btn3 {
        margin-top: 10px !important;
    }
}

@media screen and (min-width:421px) and (max-width:1200px) {

    /* .btn1 {
        margin:  10px auto !important;
        width: 100% !important;
    } */
    .main_container_pdf_history {
        width: 90%;
    }

}

@media screen and (min-width:1200px) and (max-width:1587px) {

    .btn4 {
        margin-top: 10px !important;
        /* width: 100% !important; */
    }

}

@media screen and (min-width:627px) and (max-width:1058px) {

    .btn4 {
        margin-top: 10px !important;
        /* width: 100% !important; */
    }

}

.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    display: none !important;
}

.variablebox,
.linktext {
    width: 100%;
}

.url {
    margin-right: 0px !important;
}

.pdficon_main {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.file_upload .MuiGrid-container {
    width: 100%;
}

.file_upload {
    font-size: small;
    display: flex;
    align-items: center;
    text-align: left;
    color: black;
    width: 100%;
}

.pdf_icon {
    /* width: 40px !important;
    height: 40px !important; */
    margin-left: -30px !important;
}

.pdf_icon2 img {
    margin-left: -20px !important;
}

.file_name {
    display: flex;
    align-items: center;
    /* margin-left: -100px !important; */
}

.delete_icon {
    display: flex;
    align-items: center;
}

.pdficon img {
    width: 40px !important;
    height: 40px !important;
    margin-left: -4px !important;
}

.category_edit .MuiInputBase-root {
    background: rgba(0, 0, 0, 0.1) !important;
}

.MuiInputLabel-root::after {
    content: "*";
    color: red;
}

.Grid_box {
    margin-top: 10px;
    height: 78vh;
    overflow-y: auto;
}

.head {
    position: sticky;
    top: 0;
    color: white
}

.errmsg,
.err {
    display: flex !important;
    justify-content: start !important;
    flex-direction: initial !important;
    min-height: 20px !important;
    /* max-width: 450px !important; */
    margin: 0 auto;
    /* max-width: fit-content; */
}

@media screen and (min-width:0px) and (max-width:420px) {
    .file_name {
        text-align: center;
        margin: 0 15px;
    }

    .file_upload .MuiGrid-container {
        width: 100%
    }

    .file_name_txt {
        margin-left: 10px;
    }

    .btns {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        margin: auto;
    }

    /* .grid_item .MuiGrid-container {
        max-width: 600px;
    } */
    .Grid_main_Pdf_config>.MuiGrid-item {
        min-width: 700px !important;
        max-width: 1000px !important;
    }

    .Grid_box {
        height: auto;
    }

}

@media screen and (min-width:421px) and (max-width:600px) {
    .file_name {
        text-align: center;
        margin: 0;
    }

    .file_upload .MuiGrid-container {
        width: 100%
    }

    .Grid_main_Pdf_config>.MuiGrid-item {
        min-width: 700px !important;
        max-width: 1000px !important;
    }

    .Grid_box {
        height: auto;
    }
}

.file_name_txt,
.file_upload {


    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;


}

.editErr {
    width: 100% !important;
    display: flex;
    justify-content: start;
    margin: 10px auto;
    min-width: none !important;
    min-height: 10px !important;
}

.errPdfConfig {
    display: flex !important;
    justify-content: start !important;
    flex-direction: row !important;
    width: 100% !important;
    min-width: none !important;
    min-height: 10px !important;
}

/* .css-1xaekgw{
    margin: 10px ;
} */
.Grid_main_Pdf_config {
    max-width: 100% !important;
}

@media screen and (min-width:900px) {
    .Grid_box_Pdf_config {
        margin: 10px 10px 0px 10px;
    }
}

.MuiPaper-elevation8 {
    /* left: 51% !important; */
    min-width: 400px !important
}

.MuiPaper-elevation8 ul {
    max-width: 200px !important;
}