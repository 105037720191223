.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-x: hidden !important;
}

.css-ypiqx9-MuiDialogContent-root,
.errorValidationTableContainer,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-x: hidden !important;
}

.validationPopupV1HeaderRow th {
    padding: 10px 16px !important;
    color: white !important;
    background-color: #D9A22E !important;
}

.validationPopupV1BodyRow td {
    padding: 6px 16px !important;
    background-color: #F1F1F1 !important;
}