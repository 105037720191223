.file-upload-container {
    border: 2px dashed #dcdcdc;
    border-radius: 8px;
    background: linear-gradient(180deg, #FFFFFF 0%, #EDE7DB 100%);
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.file-upload-container:hover {
    border-color: #2765ff;
}

.file-upload-container:hover {
    border-color: #d9a22e;
}

.file-input {
    display: none;
}

.file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    color: #666;
    font-size: 24px !important;
    margin-bottom: 8px;
}

.upload-text {
    color: #666;
    margin: 0;
    font-size: 14px;
}

.browse-link {
    color: #d9a22e;
    text-decoration: underline;
}

.selected-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
}

.file-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.file-icon {
    color: #d9a22e;
}

.file-name {
    color: #333;
    font-size: 14px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clear-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    color: #666;
}

.clear-button:hover {
    color: #d9a22e;
}

.clear-icon {
    font-size: 18px !important;
}

.announcementAlert :global(.MuiModal-root) {
    flex-direction: row !important;
    max-height: none !important;
}